@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=Poppins&display=swap');

.blog-post-wrapper {
  padding-bottom: 100px;
}

.slide {
  opacity: 0;
  transition: 1s ease;
}

.slide-active {
  opacity: 1;
  transition: 1s;
  transform: scale(1.08);
}

.nav-links-warpeer > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #1e1e1e !important;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
}
a:hover {
  color: #0f001a !important;
}
.LINKS-MAIN-WRAPPER {
  text-decoration: none;
  color: #0f001a;
}

.nav-links-warpeer > a:hover {
  color: #009b93;
}

.Fixed-Nav-Fix {
  padding-top: 100px;
  opacity: 0;
}

.Navabr_position {
  padding-top: 50px;
}
.nav-link {
  color: #1e1e1e;
  text-decoration: none;
}
.hero-main-wrapper {
  background: linear-gradient(#bee9e7 -17.33%, rgba(0, 155, 147, 0) 75.91%),
    #ffffff;
  box-shadow: 0px 4px 16px rgba(75, 0, 129, 0.26);
  padding-top: 50px;
}

#app {
  height: 80px;
  opacity: 0;
}

#about {
  height: 100px;
  opacity: 0;
}
#works {
  height: 100px;
  opacity: 0;
}
#home {
  background: linear-gradient(#bee9e7 -17.33%, rgba(0, 155, 147, 0) 75.91%),
    #ffffff;
  height: 100px;
  opacity: 0;
}

.satisfied-wrapper-heading > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  /* identical to box height, or 118% */

  letter-spacing: 0.25px;

  color: white;
  margin-bottom: 0px;
}

.navbar_links_wrapper {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #1e1e1e;
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: none;
  margin-left: 12px;
  margin-right: 12px;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.hero_text_heading {
  margin-bottom: 40px;
}

.hero_text_heading > h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 70px;
  /* identical to box height, or 140% */

  letter-spacing: 0.02em;

  color: #1e1e1e;
  margin-bottom: 0px;
}
.hero_text-para {
  margin-bottom: 64px;
}
.hero_text-para > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #1e1e1e;
  margin-bottom: 0px;
}

.hero_main_wrapper {
  margin-top: 136px;
  margin-bottom: 186px;
}
.btn-android {
  border: none;
}

.btn-apple {
  border: none;
}

.white_dots {
  margin-left: -55px;
}

.fb-footer-icon {
  margin-left: 4px;
}

/* 
========================Section 2 Statred===================== */
.invert-comma-section-wrapper {
  margin-top: 120px;
  margin-bottom: 115px;
}

.eshaafi_section-para > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #260041;
}

.inverted_comma_text {
  margin-left: 20px;
}

.eshaafi-card_img-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
.eshaafi-card-heading > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  letter-spacing: 0.02em;

  /* Black */

  color: #1e1e1e;
}
.eshaafi-card-heading {
  margin-left: 32px;
}
.eshaafi-card-para {
  margin-bottom: 49px;
}
.eshaafi-card-para-spacing {
  margin-bottom: 96px;
}
.eshaafi-card-para > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  color: #0f001a;

  opacity: 0.6;
}

.card-main-wrapper {
  background: #ffffff;
  box-shadow: 5px 20px 50px rgba(0, 155, 147, 0.2);
  border-radius: 10px;
}

.card-wrapper {
  margin-top: 67px;
}

.white_dots_wrapper {
  margin-left: -75px;
  position: relative;
  z-index: -1;
}

.white_dots_wrapper_ {
  margin-bottom: -70px;
  margin-left: -75px;
  position: relative;
  z-index: -1;
}
.telehealth-heading {
  margin-top: 55px;
  margin-bottom: 196px;
}
.telehealth-heading > h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 56px;
  /* or 140% */

  letter-spacing: 0.05em;

  /* Black */

  color: #1e1e1e;
}

.card-second-wrapper {
  margin-left: -45px;
}

.First-card-spacing {
  margin-bottom: 150px;
}

.how_it_work_wrapper {
  background: rgba(0, 155, 147, 0.1);
  padding-top: 80px;
}

.how_it_works_text {
  padding-bottom: 77px;
}

.how_it_works_text > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */

  text-align: center;

  color: #009b93;
}

.how_it_works_img {
  margin-bottom: 30px;
}

.how_it_works-heading > h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #1e1e1e;
}

.how_it_works-text > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  text-align: center;

  /* Black */

  color: #1e1e1e;
}

.three_points_wrapper {
  padding-bottom: 90px;
}
.Doctor_wrapper {
  margin-top: 120px;
  margin-bottom: 80px;
}
.Doctor_wrapper > h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #1e1e1e;
}
.Image_wrapper {
  margin-bottom: 0px;
}
.doctor-app-wrapper {
  margin-bottom: 16px;
}
.doctor-app-wrapper > h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  letter-spacing: 0.02em;

  /* Black */

  color: #1e1e1e;
}
.doctor-list-wrapper {
  padding-left: 0px !important;
}
.doctor-list-wrapper > div > li {
  list-style-type: none;
  margin-bottom: 20px;
}

.img-tik-wrapper {
  margin-right: 8px;
}

.Doctor-app-main-wrapper {
  margin-top: 160px;
}

.satisfied-wrapper {
  background: #009b94;
}

.satisfied-wrapper-heading {
  margin-top: 258px;
}

.satisfied-wrapper-text {
  margin-top: 8px;
  margin-bottom: 150px;
}

.satisfied-wrapper-text > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  letter-spacing: 0.5px;

  color: #ffffff;
}

.reviwes-wrapper {
  background: #e0f3f2;
  padding-top: 32px;
  padding-bottom: 32px;
}
.reviews-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
}

.reviwes-inverted-img {
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 24px;
}

.review-heading-wrapper {
  padding-left: 40px;
}

.reviews-starts {
  padding-left: 40px;
}
.review-heading-wrapper > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 175% */

  letter-spacing: 0.5px;

  /* Black */

  color: #1e1e1e;
  margin-bottom: 0px;
}

.reviews-starts {
  margin-top: -8px;
}
.review-lady-img {
  padding-left: 40px;
  width: 60px;
  height: 60px;
}

.review-lady-img {
  margin-right: 0px;
  margin-top: -4px;
}

.stars-img-text-wrapper {
  padding-bottom: 24px;
}

.row-footer {
  min-height: 100%;
  position: relative;
}

.footer-position {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}

.reviews-para-wrapper > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.5px;

  color: #1e1e1e;
}

.reviews-para-wrapper {
  margin-left: 40px;
  height: 300px;
}

.review-lady-img > img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.vision-text-first > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  /* or 180% */

  /* Black */

  color: #1e1e1e;
}

.vision-video {
  margin-top: 120px;
  margin-bottom: 24px;
}

.vision-last-spacing {
  margin-bottom: 0px;
}

.Get-eShaafi-App {
  background: #e0f3f2;
}

.Get-eShaafi-App-wrapper {
  padding-top: 64px;
  padding-bottom: 64px;
}

.Get-eShaafi-App-heading {
  margin-bottom: 16px;
}

.Get-eShaafi-App-heading > h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  /* identical to box height, or 118% */

  letter-spacing: 0.25px;

  color: #1e1e1e;
  margin-bottom: 0px;
}

.Get-eShaafi-App-para {
  margin-bottom: 52px;
}

.Get-eShaafi-App-para > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  letter-spacing: 0.5px;

  /* Black */

  color: #1e1e1e;
  margin-bottom: 0px;
}
.Get-eShaafi-App-download {
  margin-bottom: 4px;
}
.Get-eShaafi-App-download > h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;

  color: #1e1e1e;
  margin-bottom: 0px;
}
.Get-eShaafi-App-input > input {
  width: 70%;
  height: 48px;
  outline: none;
  border: none;
  background: #ffffff;
  border-radius: 4px;
  padding-left: 8px;
}

.Get-eShaafi-App-input > input::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  letter-spacing: 0.5px;

  color: #5c5c5c;
}

.input-color {
  border: none;
}
.input-filed-color > input {
  border: 1px solid red;
  width: 70%;
  height: 48px;

  background: #ffffff;
  border-radius: 4px;
  padding-left: 8px;
}
.input-filed-color > button {
  width: 30%;
  height: 48px;
  outline: none;
  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 1.25px;

  color: #ffffff;
  background: #009b94;
  border-radius: 4px;
}

.Get-eShaafi-App-input > button {
  width: 30%;
  height: 48px;
  outline: none;
  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 1.25px;

  color: #ffffff;
  background: #009b94;
  border-radius: 4px;
}

.Get-eShaafi-App-btn {
  margin-top: 6px;
}

.Get-eShaafi-App-second-image {
  margin-top: 100px;
}

.footer-main-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}
.footer-phone > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #1e1e1e;
}
.footer-images {
  margin-right: 20px;
  margin-top: -4px;
}

.Footer {
  border-bottom: 1px solid #e5e5e5;

  margin-bottom: 25px;
}

.youtube_links {
  margin-top: 20px;
}

.left-arrow {
  width: 24px;
  height: 24px;
  background: transparent;
  outline: none;
  border: none;
  margin-bottom: 16px;
}

.left-arrow > img {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .hero_main_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .white_dots {
    display: none;
  }
  .app-img-wrapper {
    margin-bottom: 100px;
  }
  .white_dots_wrapper {
    display: none;
  }
  .card-second-wrapper {
    margin-left: 0px;
  }

  .eshaafi-card-para > p {
    padding: 16px;
  }
  .invert-comma-section-wrapper {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .telehealth-heading {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .First-card-spacing {
    margin-bottom: 40px;
  }
  .white_dots_wrapper_ {
    display: none !important;
  }
  .card-wrapper {
    margin-bottom: 67px;
  }

  .Image_wrapper {
    margin-bottom: 0px;
  }
  .Doctor-app-main-wrapper {
    margin-top: 0;
    margin-bottom: 80px;
  }
  .reviews-para-wrapper > p {
    padding-right: 24px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .hero_main_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .white_dots {
    display: none;
  }
  .app-img-wrapper {
    margin-bottom: 60px;
  }
  .white_dots_wrapper {
    display: none;
  }

  .eshaafi-card-para > p {
    padding: 16px;
  }

  .card-second-wrapper {
    margin-left: 0px;
  }
  .invert-comma-section-wrapper {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .telehealth-heading {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .First-card-spacing {
    margin-bottom: 40px;
  }

  .white_dots_wrapper_ {
    display: none !important;
  }

  .card-wrapper {
    margin-bottom: 67px;
  }

  .Image_wrapper {
    margin-bottom: 0px;
  }
  .Doctor-app-main-wrapper {
    margin-top: 0;
    margin-bottom: 80px;
  }

  .reviews-para-wrapper > p {
    padding-right: 24px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 568px) {
  .white_dots {
    display: none;
  }
  .app-img-wrapper {
    margin-bottom: 60px;
  }
  .hero_main_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .white_dots_wrapper {
    display: none;
  }

  .card-second-wrapper {
    margin-left: 0px;
  }

  .eshaafi-card-para > p {
    padding: 16px;
  }
  .invert-comma-section-wrapper {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .telehealth-heading {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .First-card-spacing {
    margin-bottom: 40px;
  }

  .white_dots_wrapper_ {
    display: none !important;
  }

  .card-wrapper {
    margin-bottom: 67px;
  }
  .Image_wrapper {
    margin-bottom: 0px;
  }
  .Doctor-app-main-wrapper {
    margin-top: 0;
    margin-bottom: 80px;
  }

  .reviews-para-wrapper > p {
    padding-right: 24px;
  }
}
@media only screen and (max-width: 568px) and (min-width: 270px) {
  .res-img {
    width: 80%;
    height: 80%;
  }
  .btn-android {
    background-color: transparent;
  }
  .btn-apple {
    background-color: transparent;
  }
  .hero_text_heading > h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    letter-spacing: 0.02em;
    color: #1e1e1e;
    margin-bottom: 0px;
  }
  .hero_main_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .white_dots {
    display: none;
  }
  .app-img-wrapper {
    margin-bottom: 40px;
  }
  .inverted_comma_text {
    margin-left: 0px;
  }

  .invert-comma-section-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .inverted_comma_wrapper {
    margin-bottom: 16px;
  }
  .white_dots_wrapper {
    display: none;
  }

  .card-second-wrapper {
    margin-left: 12px;
    margin-right: 12px;
  }
  .eshaafi-card-para > p {
    padding: 16px;
  }

  .telehealth-heading {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .First-card-spacing {
    margin-bottom: 16px;
  }

  .white_dots_wrapper_ {
    display: none !important;
  }

  .card-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .Image_wrapper {
    margin-bottom: 0px;
  }
  .Doctor-app-main-wrapper {
    margin-top: 0;
    margin-bottom: 80px;
  }

  .reviews-para-wrapper > p {
    padding-right: 32px;
  }
  .images-wrapper {
    display: none;
  }
  .apple-world {
    width: 80%;
    height: 80%;
  }
  .footer-image-wrapper {
    display: flex;
    justify-content: center;
  }
  .footer-main-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .satisfied-wrapper-text > h4 {
    padding-left: 20px;
    padding-right: 16px;
  }
  .hero_text_heading {
    margin-bottom: 16px;
  }
  .hero_text-para {
    margin-bottom: 16px;
  }
  .how_it_work_wrapper {
    margin-top: 0px;
  }
  .Doctor_wrapper {
    margin-top: 80px;
  }
  .satisfied-wrapper-heading {
    margin-top: 80px;
  }
  .satisfied-wrapper-text {
    margin-bottom: 80px;
  }
  .how_it_works_img {
    margin-bottom: 16px;
  }

  .margin_bootom_wrapper {
    margin-bottom: 40px;
  }

  .doctor-list-wrapper > div > li {
    margin-bottom: 16px;
  }
  #works {
    height: 100px;
  }
  .d-cotor-app-container {
    margin-left: 12px;
    margin-right: 12px;
  }

  .vision-video {
    margin-left: 12px;
    margin-right: 12px;
  }
  .vision-text-first {
    margin-left: 12px;
    margin-right: 12px;
  }
  .Get-eShaafi-App-wrapper {
    margin-left: 12px;
    margin-right: 12px;
  }
  .reviews-para-wrapper {
    height: 200px;
  }
  .footer-main-wrapper {
    margin-left: 12px;
    margin-right: 12px;
  }
  .eshaafi-card-heading {
    margin-left: 16px;
  }
  .Doctor-app-main-wrapper {
    margin-bottom: 0px;
  }
}

.footer_bootom {
  list-style-type: none;
  padding-left: 0px;
}

li {
  cursor: pointer;
}
.privacy-policy-page-wrapper {
  margin-top: 120px;
}

.collection_of_data > h4 {
  color: #009b94;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
}

.privacy_modal_header > h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.02em;
  color: #1e1e1e;
  margin-bottom: 16px;
}

.privacy_modal_text > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #1e1e1e;
}

.privacy_modal_text_bold > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 140% */

  letter-spacing: 0.02em;

  color: #1e1e1e;
}

.privacy_modal_text > li {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #1e1e1e;
}

.privacy-modal-text-margin {
  margin-left: 24px;
}

.accordian-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  color: #1e1e1e;
}

.faqs-heading {
  margin-top: 24px;
  margin-bottom: 24px;
}

.accordion-button:not(.collapsed) {
  color: #009b94 !important;
  background-color: white !important;
}

.terms-border-top {
  padding-top: 16px;
  border-top: 1px solid #e5e5e5;
  margin-top: 100px;
}

.modal-img-wrapper {
  width: 70%;
}

.modal-img-wrapper > img {
  width: 100%;
}

.modal-img-wrapper > h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 140% */

  letter-spacing: 0.02em;

  color: #009b94;
  margin-bottom: 0px;
}
